import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			fieldId: '',
			value: undefined,
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}


		var req = {
			fields: this.resultFields,
			filter: {

			},
			sorting: 'random',
			max: parseInt(this.$el.dataset.maxvalue, 10)
		};
		var value;
		var valueType = this.$el.dataset.valuetype;
		if (valueType === 'number') {
			value = parseInt(this.$el.dataset.value, 10);
		} else if (valueType == 'boolean') {
			value = true;
		} else {
			value = this.$el.dataset.value;
		}
		var fieldId = this.$el.dataset.fieldid;
		req.filter[fieldId] = value;

		this.doSearchNow(req);

	},


	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		doSearchNow: function (req) {
			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
				});

		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
	},
	filters: {
		formatfeedback: function (value) {
			if (!value) return '';
			if (Number.isInteger(value)) return value.toFixed();
			return (value.toFixed(1).replace('.', ','));
		}
	}

};